<template>
  <section id="existed-case-information">
    <div class="default-modal">
      <el-dialog
        v-model="visible"
        :title="`${getError ? getError : 'Patient Already with Genetic Test'}`"
        :before-close="cancelCase"
        width="70%"
        :close-on-click-modal="false"
        class="existed-caseinfo-dialog"
      >
        <div class="dialog-header">
          <span class="header-part">{{
            `${getError ? getError : "Patient Already with Genetic Test"}`
          }}</span>
          <el-button class="close-btn" @click="cancelCase">
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon" />
          </el-button>
        </div>
        <div class="all-cases-view">
          <div class="popup-date">
            <div class="primary-content-view-new" v-loading="loading">
              <div
                class="listings listings__table listings__all-cases"
                id="all-cases-listings"
              >
                <el-table
                  :data="existingDetails"
                  style="width: 100%"
                  height="250"
                >
                  <el-table-column label="Accession ID" width="150">
                    <template #default="scope">
                      <div class="date">
                        {{
                          scope.row.accession_id ? scope.row.accession_id : "--"
                        }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Patient Name" width="200">
                    <template #default="scope">
                      <span
                        class="patient-name"
                        v-if="scope.row.patient_info.middle_name"
                      >
                        {{
                          scope.row.patient_info
                            ? scope.row.patient_info.first_name +
                              " " +
                              scope.row.patient_info.middle_name +
                              " " +
                              scope.row.patient_info.last_name
                            : "--"
                        }}
                      </span>
                      <span class="patient-name" v-else>
                        {{
                          scope.row.patient_info
                            ? scope.row.patient_info.first_name +
                              " " +
                              scope.row.patient_info.last_name
                            : "--"
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Date Of Service"
                    width="150"
                    prop="collection_date"
                  >
                    <template #default="scope">
                      <div class="d-flex">
                        <div class="date pr-5">
                          {{
                            momentWithTimezone(
                              scope.row.collection_date,
                              "MM-DD-yyyy"
                            )
                          }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="Patient DOB" width="150">
                    <template #default="scope">
                      <div
                        class="date"
                        v-if="scope.row.patient_info.date_of_birth"
                      >
                        {{ formatDate(scope.row.patient_info.date_of_birth) }}
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Case Type"
                    width="180"
                    prop="case_types"
                  >
                    <template #default="scope">
                      <div
                        class="case-type"
                        v-if="
                          scope.row.case_types &&
                          scope.row.case_types.length > 1
                        "
                      >
                        <el-dropdown>
                          <span class="case-data-type">
                            {{ getCaseTypes(scope.row.case_types)[0] }}
                            <img
                              src="@/assets/images/case-type-arrow.svg"
                              alt="icon"
                            />
                          </span>

                          <template #dropdown>
                            <el-dropdown-menu class="case-type-dropdown">
                              <el-dropdown-item
                                class="cases-view"
                                v-for="(caseType, index) of getCaseTypes(
                                  scope.row.case_types
                                )"
                                :key="index"
                                >{{ caseType }}</el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                      <div v-else class="case-type">
                        <span class="case-data-type">{{
                          getCaseTypes(scope.row.case_types)[0]
                        }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="ordering_facility"
                    label="Ordering Facilities"
                    min-width="300"
                  >
                    <template #default="scope">
                      <span class="table_cell-ellipsis">
                        {{
                          scope.row.hospital ? scope.row.hospital.name : "--"
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Physician" width="180">
                    <template #default="scope">
                      <span class="table_cell-ellipsis">{{
                        scope.row.ordering_physician
                          ? scope.row.ordering_physician.name.includes("Dr")
                            ? scope.row.ordering_physician.name
                            : "Dr " + scope.row.ordering_physician.name
                          : "--"
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <span v-if="errorMessage">
              <p class="err">{{ errorMessage }}</p>
            </span>
            <div class="dialog-footer">
              <ul>
                <li v-if="newCase || caseUpdate">
                  <div>
                    <el-button
                      class="blue-save-button success-button"
                      v-if="duplicateTest"
                      @click="approveCase()"
                      >Approve & Continue</el-button
                    >
                    <el-button
                      class="blue-save-button"
                      @click="createNewCase()"
                      v-if="duplicateTest"
                    >
                      Skip & New Case
                    </el-button>

                    <el-button
                      class="blue-save-button success-button"
                      @click="addToPendingList"
                      :loading="loading"
                      v-if="
                        hasPermission('pending_genetic_cases', 'add') &&
                        (!nonGeneticTest || !geneticTest)
                      "
                      >Add To Approval Pending Cases</el-button
                    >
                  </div>
                </li>
                <li v-else-if="convert || accession">
                  <div>
                    <el-button
                      class="blue-save-button success-button"
                      v-if="duplicateTest"
                      @click="approveCase()"
                      >Approve & Continue</el-button
                    >
                    <el-button
                      class="blue-save-button success-button"
                      @click="addToPendingList"
                      :loading="loading"
                      v-if="
                        hasPermission('pending_genetic_cases', 'add') &&
                        (!nonGeneticTest || !geneticTest)
                      "
                      >Add To Approval Pending Cases</el-button
                    >
                  </div>
                </li>
                <li v-else-if="phlebioOrder">
                  <el-button
                    class="blue-save-button success-button"
                    @click="addToPendingList"
                    :loading="loading"
                    >Add To Approval Pending Cases</el-button
                  >
                </li>
                <li v-else>
                  <el-button class="cancel-button" @click="goToNewCase()"
                    >Cancel</el-button
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import caseConstants from "@/config/constants/caseConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import moment from "moment";
export default {
  props: [
    "existedCase",
    "caseType",
    "case",
    "newCase",
    "caseInformation",
    "existedOrder",
    "convert",
    "accession",
    "existedCaseInfo",
    "validationErrorMsg",
    "caseUpdate",
    "phlebioOrder",
    "phlebioInformation",
  ],
  mixins: [UserPermissionsHelper],
  computed: {
    ...mapGetters("pendingCases", ["getPendingCaseInforamtion"]),
    ...mapGetters("errors", ["getError", "getMessage", "getErrors"]),
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },

    nonGeneticTest() {
      const nonGeneticTest = this.caseType.filter((e) => {
        return this.nonGeneticCaseTypes.indexOf(e) != -1;
      });
      return nonGeneticTest && nonGeneticTest.length && nonGeneticTest[0]
        ? false
        : true;
    },
    geneticTest() {
      const geneticTest = this.caseType.filter((e) => {
        return this.geneticCaseTypes.indexOf(e) != -1;
      });
      return geneticTest && geneticTest.length && geneticTest[0] ? false : true;
    },
    duplicateTest() {
      const duplicateCase = this.caseType.filter((e) => {
        return this.duplicateCaseTypes.indexOf(e) != -1;
      });
      return duplicateCase && duplicateCase.length && duplicateCase[0]
        ? true
        : false;
    },
  },
  data() {
    return {
      visible: true,
      existingDetails: [],
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      geneticCaseTypes: caseConstants.GENETIC_TEST_CASE_TYPES,
      nonGeneticCaseTypes: caseConstants.NON_GENETIC_TEST_CASE_TYPES,
      duplicateCaseTypes: caseConstants.DUPLICATE_CASE_TYPES,
      loading: false,
      errorMessage: "",
    };
  },
  mounted() {
    this.existingDetails = this.existedCase;
  },
  methods: {
    async goToNewCase() {
      this.errorDiloag = false;

      this.existingDetails = [];
      this.$emit("closeDialog", false);
    },
    async cancelCase() {
      this.errorDiloag = false;

      this.existingDetails = [];
      this.$emit("closePopupDialog", false);
    },

    async createNewCase() {
      this.errorDiloag = false;
      this.existingDetails = [];
      this.$emit("newCaseCreation", false);
    },
    async approveCase() {
      this.errorDiloag = false;
      this.existingDetails = [];
      this.$emit("approveCase", false);
    },
    prepareParams() {
      console.log(this.caseInformation, this.existedOrder);
      let params = {};
      if (this.newCase) {
        params = this.caseInformation;
      } else if (this.convert) {
        params = this.existedOrder;
      } else if (this.accession) {
        params = this.existedCaseInfo;
      } else if (this.phlebioOrder) {
        params = this.phlebioInformation;
      }
      return params;
    },
    async addToPendingList() {
      this.errorMessage = "";
      this.loading = true;
      let caseData = this.prepareParams();
      let params = {
        case_data: caseData,
        previous_data: this.existingDetails,
      };
      await this.$store.dispatch("pendingCases/addToPendingCase", params);
      if (this.getPendingCaseInforamtion) {
        successNotification(this.getPendingCaseInforamtion.message);

        this.$router.push({
          name: "PendingCaseView",
          params: { case_id: this.getPendingCaseInforamtion.data._id },
        });
      } else {
        if (this.getError) {
          errorNotification(
            this.getError || "Error while adding to pending list"
          );
        } else if (this.getErrors) {
          let jsonData = this.getErrors;
          this.errorMessage = jsonData["case_data.tests_info"];
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#existed-case-information {
  .default-modal .el-dialog__body {
    border-radius: 5px;
  }
  .listings__table .el-table table {
    border-collapse: separate;
    border-spacing: 0px 5px;
  }
  .listings__table .el-table table tbody tr td .cell {
    line-height: 25px;
  }
  .listings__table .el-table table thead tr th.el-table__cell {
    padding: 0.65em 0 0.5em 0 !important;
    background: #273480 !important;
  }
  .listings__table .el-table table thead tr th .cell {
    color: #fff;
  }
}
</style>
